<template>
  <div class="circleManagePesonList">
    <!-- 列表 -->
    <div class="search-content">
      <van-swipe-cell v-for="item in memberList" :key="item.userBasicVO.userId">
        <div class="user-wrap" @click="goPersonalHome(item)">
          <AvatarFrame class="wrap-left" :user-info="item.userBasicVO"></AvatarFrame>
          <div class="wrap-right">
            <div class="flex-row-h-center user-name">
              <span class="username-span">{{ item.userBasicVO.username }}</span>
              <van-tag size="medium" v-if="item.identity && item.identity<=2" style="margin-left: 8px;" color="#FE5800"
                plain>{{ getIdentity(item.identity) }}
              </van-tag>
              <van-tag size="medium" v-if="item.isMuted" style="margin-left: 8px;" color="rgba(109,114,120,0.5)" plain>
                被禁言
              </van-tag>
            </div>
            <div class="user-dept">{{ item.userBasicVO.deptStr }}</div>
          </div>
        </div>
        <template #right v-if="item.identity !== 1">
          <van-button @click="vanSwipeJc(item.userBasicVO.userId)" v-if="item.isShowRemoveManage" text="解除管理员" />
          <van-button @click="vanSwipeJy(item.userBasicVO.userId)" v-if="item.isShowMuted" type="warning" text="禁言" />
          <van-button @click="vanSwipeDel(item.userBasicVO.userId)" v-if="item.isShowRemove" type="danger" text="删除" />
        </template>
      </van-swipe-cell>
    </div>
  </div>
</template>

<script>
import AvatarFrame from '@/components/avatarFrame'
import {CRICLE_IDENTITY} from "@/config/config";
import {batchAddMemberMuted, batchRemoveMember, removeManager} from "@/api/circle";

export default {
  name: 'circleManagePesonList',
  components: {
    AvatarFrame
  },
  props: {
    memberList: {
      type: Array,
      default: () => {
      }
    }
  },
  date () {
    return {
      CRICLE_IDENTITY
    }
  },
  created () {
    console.log(CRICLE_IDENTITY)

  },
  methods: {
    // 个人中心
    goPersonalHome (item) {
      if (item.userBasicVO && item.userBasicVO.userId) {
        this.$router.push({name: 'userInfoDetail', params: {userId: item.userBasicVO.userId}})
      }
    },
    getIdentity (val) {
      return CRICLE_IDENTITY[`${val}`] || ''
    },
    // 解除管理员
    vanSwipeJc (id) {
      this.$dialog.confirm({
        title: '解除管理员',
        message: '你确定要删除该成员管理员权限吗？',
        confirmButtonText: '解除管理员',
        cancelButtonText: '再考虑下'
      }).then(() => {
        removeManager({
          manageUserId: id,
          circleId: this.$route.query.circleId
        }).then(() => {
          this.$emit("reload", id)
        })
      })
    },
    // 禁言
    vanSwipeJy (userId) {
      // this.$emit('vanSwipeJy', id)
      this.$dialog.confirm({
        title: '禁言成员',
        message: '你确定要禁言该成员吗？禁言后该成员无法在圈内发帖',
        confirmButtonText: '禁言成员',
        cancelButtonText: '再考虑下'
      }).then(() => {
        batchAddMemberMuted({
          userIds: [userId],
          circleId: this.$route.query.circleId
        }).then(() => {
          this.$emit("forbidden", userId)
        }).catch(err => {
          // alert(err)
          console.log(err)
        })
      })
    },
    // 删除
    vanSwipeDel (id) {
      this.$dialog.confirm({
        title: '删除成员',
        message: '你确定要删除该成员吗？',
        confirmButtonText: '删除成员',
        cancelButtonText: '再考虑下'
      }).then(() => {
        batchRemoveMember({
          userIds: [id],
          circleId: this.$route.query.circleId
        }).then(() => {
          this.$emit("reload", id)
        }).catch(err => {
          console.log(err)
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.search-content {
  padding-left: 20px;

  .user-wrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px 0;

    .wrap-left {
      display: flex;
      width: 44px;
      height: 44px;
      overflow: hidden;
    }

    .wrap-right {
      line-height: 22px;
      margin-left: 10px;

      .user-name {
        line-height: 24px;
        font-size: 14px;
        font-weight: 500;
        text-align: LEFT;
      }

      .user-dept {
        font-size: 12px;
        color: #6d7278;
      }
    }

    .user-role {
      margin-left: 8px;
      font-size: 10px;
      color: $mainColor;
      border: 1px solid $mainColor;
      line-height: 16px;
      border-radius: 4px;
      padding: 0 4px;
    }
  }

}
</style>
