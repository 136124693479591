<template>
  <div class="circleManagePesonList">
    <!-- 列表 -->
    <div class="search-content">
      <van-swipe-cell v-for="item in mutedList" :key="item.userBasicVO.userId">
        <div class="user-wrap" @click="goPersonalHome(item)">
          <AvatarFrame class="wrap-left" :user-info="item.userBasicVO"></AvatarFrame>
          <div class="wrap-right">
            <div class="user-name">
              <span class="username-span">{{ item.userBasicVO.username }}</span>
            </div>
            <div class="user-dept">{{ item.userBasicVO.deptStr }}</div>
          </div>
        </div>
        <template #right>
          <van-button @click="vanSwipeJy(item.userBasicVO.userId)" type="warning" text="解除禁言"/>
        </template>
      </van-swipe-cell>
    </div>
  </div>
</template>

<script>
import AvatarFrame from '@/components/avatarFrame'
import {CRICLE_IDENTITY} from "@/config/config";
import {batchRemoveMemberMuted} from "@/api/circle";

export default {
  name: 'mutedList',
  components:{
    AvatarFrame
  },
  props: {
    mutedList: {
      type: Array,
      default: () => {
      }
    }
  },
  date() {
    return {
      CRICLE_IDENTITY
    }
  },
  created() {
    console.log(CRICLE_IDENTITY)

  },
  methods: {
    // 个人中心
    goPersonalHome (item) {
      if (item.userBasicVO && item.userBasicVO.userId) {
        this.$router.push({name: 'userInfoDetail', params: {userId: item.userBasicVO.userId}})
      }
    },
    getIdentity(val) {
      return CRICLE_IDENTITY[`${val}`] || ''
    },
    // 解除管理员
    vanSwipeJy(id) {
      this.$dialog.confirm({
        title: '解除成员禁言',
        message: '你确定要解除禁言吗？解除后成员可以在圈子发言',
        confirmButtonText: '解除禁言',
        cancelButtonText: '再考虑下'
      }).then(() => {
        batchRemoveMemberMuted({
          userIds: [id],
          circleId: this.$route.query.circleId
        }).then(() => {
          this.$emit("reload", id)
        })
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.search-content {
  padding-left: 20px;

  .user-wrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px 0;

    .wrap-left {
      width: 44px;
      height: 44px;
      overflow: hidden;
    }

    .wrap-right {
      line-height: 22px;
      margin-left: 10px;

      .user-name {
        line-height: 24px;
        font-size: 14px;
        font-weight: 500;
        text-align: LEFT;
      }

      .user-dept {
        font-size: 12px;
        color: #6d7278;
      }
    }

    .user-role {
      font-size: 10px;
      color: $mainColor;
      border: 1px solid $mainColor;
      line-height: 20px;
      border-radius: 4px;
      padding: 0 4px;
    }
  }

}
</style>
